*{
  padding: 0;
  margin: 0;
}

body{
  background-color: rgba(198, 198, 219, 0.696);
}

h1, h2, h3, h4, h5 {
  font-family: "Red Hat Display";
}


.navegador{
  font-family:"Raleway";
  background-color:rgba(198, 198, 219, 0.696);
}


#datos{
  margin-left: 10px;
  align-items: center;
  text-decoration: none;
  color: black;
  margin-top: 3px;
}
.infoFinal{
  background-color:  #c3bff1;
  padding: 10px;
  text-align: center;
  color: black;
}


.imagenFondo {
  background: linear-gradient(rgba(5, 7, 12, 0.8), rgba(5, 7, 12, 0.2)), url("/public/Images/InicioMar.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 20em; }

.tituloBanner {
  color: white;
  text-align: center;
  font-family: "Raleway";
  padding: 10px; }
   

.textoBanner {
  color: white;
  text-align: center;
  font-family: "Raleway"; 
  }

.botonBanner {
  align-items: center;
  padding: 5px;
  font-family: "Red Hat Display";
  background-color:white;
  color: black;}

  .container-boton{
    background-color: #2e6329;
    border: 1px solid #fff;
    position: fixed;
    z-index: 999;
    border-radius: 50%;
    bottom: 20px;
    right: 25px;
    padding: 25px;
    transition: ease 0.3s;
    animation: efecto 1.2s infinite;
}

.container-boton:hover{
    transform: scale(1.1);
    transition: 0.3s;
}

.boton{
    width: 50px;
    transition: ease 1s;
}

@keyframes efecto{
  0%{
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.85);
  }
  100%{
      box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
  }
}

.card{
  background-color: rgba(198, 198, 219, 0.696);
}

.enlaces{
  text-decoration: none;
  color: black;
}

#imagenMarilyn{
  border-radius: 400px;
  width: 100%;
  height: 100%;
}

.textoPresentacion{
  text-align:justify;
  font-family:"Red Hat Display" ;
  margin-top: 40px;

}

.accordion-button{
  background-color: rgba(179, 179, 225, 0.696);
}

.accordion-body{
  background-color: #fcfcff;
}
